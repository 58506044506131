import "../styles/index.scss";
// import Swiper JS
import Swiper, { Autoplay, EffectFade, Navigation, Pagination, Scrollbar, FreeMode } from "swiper";

// This imports work in dev but not production, cdn is temporary added for production
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import "swiper/css/pagination";

const robinSwiper = new Swiper(".robin-swiper", {
  modules: [Navigation, Pagination, Scrollbar],
  loop: true,
  allowTouchMove: true,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
    formatFractionCurrent: function (number) {
      return ("0" + number).slice(-2);
    },
    formatFractionTotal: function (number) {
      return ("0" + number).slice(-2);
    },
  },
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
  },
  autoHeight: true,
});

const awardsSwiper = new Swiper(".awards-swiper", {
  modules: [Navigation, Pagination, Scrollbar],
  loop: false,
  allowTouchMove: true,
  grabCursor: true,
  initialSlide: 0,
  slidesPerView: 1,
  breakpoints: {
    700: {
      initialSlide: 1,
      slidesPerView: 2,
      spaceBetween: 10,
      centeredSlides: true,
    },
    1076: {
      initialSlide: 1,
      slidesPerView: 3,
      spaceBetween: 20,
      centeredSlides: true,
    },
  },
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
    formatFractionCurrent: function (number) {
      return ("0" + number).slice(-2);
    },
    formatFractionTotal: function (number) {
      return ("0" + number).slice(-2);
    },
  },
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
  },
  // autoHeight: true,
});

const teamSwiper = new Swiper(".team__swiper", {
  modules: [Navigation, Pagination, Scrollbar],
  loop: true,
  allowTouchMove: true,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
    formatFractionCurrent: function (number) {
      return ("0" + number).slice(-2);
    },
    formatFractionTotal: function (number) {
      return ("0" + number).slice(-2);
    },
  },
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
  },
  autoHeight: true,
});

const benefitsSwiper = new Swiper(".benefits__swiper", {
  modules: [Navigation, Pagination, Scrollbar],
  loop: true,
  allowTouchMove: true,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
    formatFractionCurrent: function (number) {
      return ("0" + number).slice(-2);
    },
    formatFractionTotal: function (number) {
      return ("0" + number).slice(-2);
    },
  },
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
  },
  autoHeight: true,
});

const usSwiper = new Swiper(".us__swiper", {
  modules: [Navigation, Autoplay, EffectFade, Pagination],
  loop: true,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
    formatFractionCurrent: function (number) {
      return ("0" + number).slice(-2);
    },
    formatFractionTotal: function (number) {
      return ("0" + number).slice(-2);
    },
  },
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
  },
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  loop: true,
  speed: 1000,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    pauseOnMouseEnter: false,
  },
});

const jobSwiper = new Swiper(".positions__swiper", {
  modules: [Navigation, Pagination, Scrollbar],
  loop: true,
  allowTouchMove: true,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
    formatFractionCurrent: function (number) {
      return ("0" + number).slice(-2);
    },
    formatFractionTotal: function (number) {
      return ("0" + number).slice(-2);
    },
  },
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
  },
});

const croatianSwiper = new Swiper(".croatian__swiper", {
  modules: [Navigation, Pagination, Scrollbar],
  loop: true,
  allowTouchMove: true,
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
    formatFractionCurrent: function (number) {
      return ("0" + number).slice(-2);
    },
    formatFractionTotal: function (number) {
      return ("0" + number).slice(-2);
    },
  },
  navigation: {
    nextEl: ".swiper-next",
    prevEl: ".swiper-prev",
  },
  autoHeight: true,
});

const bannerSwiper = new Swiper(".banner__swiper", {
  modules: [Navigation, Scrollbar, FreeMode, Autoplay],
  loop: true,
  freeMode: true,
  spaceBetween: 0,
  grabCursor: false,
  slidesPerView: 10,
  autoplay: {
    delay: 1,
    disableOnInteraction: false,
  },
  freeMode: {
    enabled: true,
  },
  speed: 10000,
  freeModeMomentum: true,
});

var benefitsList = () => {
  var benefitsTitle = document.querySelector(".benefits__title");
  var benefitsText = document.querySelector(".benefits__text");
  var benefitItems = Array.from(document.querySelectorAll(".benefits__list-item"));
  benefitItems.forEach(function (item) {
    item.addEventListener("mouseover", function () {
      var itemTitle = item.querySelector(".benefits__list-item-title").innerHTML;
      var itemText = item.querySelector(".benefits__list-item-text").innerHTML;
      benefitItems.forEach(function (item) {
        item.classList.remove("active");
      });
      item.classList.add("active");
      benefitsTitle.innerHTML = itemTitle;
      benefitsText.innerHTML = itemText;
    });

    item.addEventListener("click", function () {
      var itemTitle = item.querySelector(".benefits__list-item-title").innerHTML;
      var itemText = item.querySelector(".benefits__list-item-text").innerHTML;
      benefitItems.forEach(function (item) {
        item.classList.remove("active");
      });
      item.classList.add("active");
      benefitsTitle.innerHTML = itemTitle;
      benefitsText.innerHTML = itemText;
    });
  });
};

var navbar = document.querySelector(".navbar-burger");
var body = document.querySelector("body");

navbar.addEventListener("click", function () {
  navbar.classList.toggle("is-active");
  body.classList.toggle("menu-opened");
});

var helpBannerOptions = Array.from(document.querySelectorAll(".help-choice"));
helpBannerOptions.forEach(function (bannerOption) {
  bannerOption.addEventListener("click", function () {
    setBannerImg(bannerOption.id);
  });
});

function setBannerImg(name) {
  document.querySelector(".help-banner.is-selected").classList.remove("is-selected");
  document.querySelector(".help-choice.is-selected").classList.remove("is-selected");

  document.querySelector(`.help-banner.${name}`).classList.add("is-selected");
  document.getElementById(name).classList.add("is-selected");
}

benefitsList();
